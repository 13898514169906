import { FunctionComponent } from 'react';

import useTrackUtm from '@gojob/tracking/hooks/useTrackUtm';
import { useLocation } from '@reach/router';

const TrackUtm: FunctionComponent = () => {
  const { search } = useLocation();
  useTrackUtm(search);
  return null;
};

export default TrackUtm;
