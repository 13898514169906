declare global {
  interface Window {
    dataLayer?: any[];
  }
}

export type TrackArgumemnts =
  | [
      'page',
      {
        pageCategory: string;
        pageName: string;
      }
    ]
  | [
      'click',
      {
        eventCategory?: string;
        eventAction?: string;
        eventName?: string;
      }
    ];

const track = (...args: TrackArgumemnts) => {
  const [event, parameters = {}] = args;

  if (typeof window !== 'undefined') {
    window?.dataLayer?.push({ event, ...parameters });
  }
};

export type Dimensions = {
  envWork?: 'development' | 'production';
  language?: string;
  userAuthenticated?: '0' | '1';
  userId?: string;
  userRole?: 'admin' | 'gojobber';
};

export const updateTrackingDimensions = (arg: Dimensions) => {
  if (typeof window !== 'undefined') {
    window?.dataLayer?.push(arg);
  }
};

export default track;
