import React from 'react';

import ThemeProvider from '@gojob/ui-bits/styles/ThemeProvider';
import theme from '@gojob/ui-bits/styles/themes/front';
import { WrapRootElementBrowserArgs } from 'gatsby';

import SharedTokenProvider from '../src/components/SharedTokenProvider';

const wrapRootElement = (args: WrapRootElementBrowserArgs) => {
  const { element } = args;

  return (
    /** Recover the shared token as cookie by my.gojob.com */
    <SharedTokenProvider>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </SharedTokenProvider>
  );
};

export default wrapRootElement;
