import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

const clientId = process.env.AXEPTIO_CLIENT_ID;

interface AxeptioCookieContentProps {
  i18n_lang?: string;
}

const AxeptioCookieConsent: FunctionComponent<AxeptioCookieContentProps> = (props) => {
  const { i18n_lang } = props;

  return (
    <Helmet>
      <script>
        {`window.axeptioSettings = {
            clientId: "${clientId}",
            cookiesVersion: "${i18n_lang}",
            userCookiesDomain: "gojob.com"
          };
 
          (function(d, s) {
            var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
            e.async = true; e.src = "//static.axept.io/sdk-slim.js";
            t.parentNode.insertBefore(e, t);
          })(document, "script");`}
      </script>
    </Helmet>
  );
};

export default AxeptioCookieConsent;
