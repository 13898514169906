import React from 'react';

import { Global } from '@emotion/core';
import CssBaseline from '@gojob/ui-bits/CssBaseline';
import theme from '@gojob/ui-bits/styles/themes/front';
import { WrapPageElementBrowserArgs } from 'gatsby';

import AxeptioCookieConsent from '../src/components/AxeptioCookieConsent';
import TrackUtm from '../src/components/TrackUtm';

/** Globally import Montserrat typeface to be used across site */

interface PageContext {
  i18n_lang?: string;
}

const wrapPageElement = (args: WrapPageElementBrowserArgs) => {
  const { element, props } = args;
  const pageContext: PageContext = props.pageContext;

  return (
    <>
      <CssBaseline />
      <Global
        styles={{
          'html, body, #___gatsby, #gatsby-focus-wrapper': {
            height: '100%',
            backgroundColor: theme.colors.white,
          },
          body: {
            position: 'relative',
            WebkitOverflowScrolling: 'touch',
            padding: theme.spacing(8, 0, 0),
          },
        }}
      />
      <TrackUtm />
      <AxeptioCookieConsent i18n_lang={pageContext.i18n_lang} />

      {element}
    </>
  );
};

export default wrapPageElement;
