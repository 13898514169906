import React, { createContext, FunctionComponent, ReactNode, useEffect, useState } from 'react';

import Cookie from 'js-cookie';
import JwtDecode from 'jwt-decode';
import { updateTrackingDimensions } from '../../tracking/track';

export type SharedTokenContextType = string | undefined;

export const SharedTokenContext = createContext<SharedTokenContextType>(undefined);

export type SharedTokenProviderProps = {
  /** Name of the cookie containing the JWT (defaults to "gojob_token") */
  name?: string;
  children?: ReactNode;
};

/** Empirical type based on what we know about my.gojob.com token  */
export type JWTData = {
  workerId?: string;
  uid?: string;
};

const SharedTokenProvider: FunctionComponent<SharedTokenProviderProps> = (props) => {
  const { name = 'gojob_token', children } = props;

  const [token, setToken] = useState<string | undefined>();
  /** Delay token recovery to after rehydration state */
  useEffect(() => setToken(Cookie.get(name)), []);

  /** Add tracking information */
  useEffect(() => {
    if (token) {
      try {
        const { uid } = JwtDecode<JWTData>(token);
        if (uid) {
          return updateTrackingDimensions({ userRole: 'gojobber', userAuthenticated: '1', userId: uid });
        }
      } catch {
        /** Ignore all errors */
      }
    }
    /** If there's no token or we haven't resolved the worker id, track as not authenticated user */
    updateTrackingDimensions({ userRole: undefined, userAuthenticated: '0', userId: undefined });
  }, [token]);

  return <SharedTokenContext.Provider value={token}>{children}</SharedTokenContext.Provider>;
};

export default SharedTokenProvider;
